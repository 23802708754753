import { AxiosResponse } from "axios";
import { NotificationPermissions, NotificationResponse, SetNotificationPermissionDto } from "../models/notification";
import { notificationClient } from "../apiClient";

export const GetNotificationPermissions = async(): Promise<NotificationResponse<NotificationPermissions>> => {
    try {
        const response: AxiosResponse<NotificationResponse<NotificationPermissions>> = await notificationClient().get('/permission/');
        return response.data;
    } catch (error) {
        console.error('Error fetching notification permisssions:', error);
        throw new Error('Failed to fetch notification permissions');
    }
}

export const SetUserNotificationPermission = async(data:SetNotificationPermissionDto): Promise<any> => {
    try {
        const response: AxiosResponse<NotificationResponse<SetNotificationPermissionDto>> = await notificationClient().post('/permission/',data);
        return response.data;
    } catch (error) {
        console.error('Error set notification permisssions:', error);
        throw new Error('Failed to set notification permissions');
    }
}

export const GetAllNotifications = async(perPage:number = 30, page:number = 1): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await notificationClient().get(`/notification/myNotifications?perPage=${perPage}&page=${page}&sortBy=createdAt&sortOrder=ASC&type`);
        return response.data
    } catch (error) {
        console.error('Error get all notification:', error);
        throw new Error('Failed to get all notification');
    }
}