import { Grid, Typography } from "@mui/material";
import { FC } from "react";
import { Lock, SurveyBookPenBlue } from "../assests";
import ContentLinearProgressBar from "./ContentLinearProgressBar";
import SurveyStartAnswerButton from "./SurveyStartAnswerButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate } from "react-router";
import { openBecomePremiumModal } from "../redux/generalControllerSlice";

interface Props {
  survey_token: string;
  survey_title: string;
}

const SurveyForParentItem: FC<Props> = ({
  survey_token = "",
  survey_title = "",
}) => {
  const { user } = useSelector((state: RootState) => state.authMicro);
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const containerStyle = {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 10px 20px rgba(0, 64, 128, 0.1)",
    borderRadius: "12px",
    padding: "20px 22px",
  };

  const iconPartStyle = {
    backgroundColor: "#F9F9F9",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const startSurveyTriggered = () => {
    if (user?.isUserPremium) {
      navigate(`/survey/${survey_token}`);
    } else {
      dispatch(openBecomePremiumModal());
    }
  };

  return (
    <Grid container style={containerStyle} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={1} sm={2} md={1} style={iconPartStyle}>
        <img src={SurveyBookPenBlue} alt="" />
      </Grid>
      <Grid
        item
        xs={3}
        sm={6}
        md={7}
        style={{ textAlign: "start", padding: "10px", marginBottom: "10px" }}
      >
        <Typography
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            lineHeight: "28px",
            color: "#171725",
            fontWeight: "700",
            marginBottom: "4px",
          }}
        >
          {survey_title}
        </Typography>
        <Typography
          style={{
            fontSize: "16px",
            fontFamily: "Montserrat",
            lineHeight: "24px",
            color: "#66707A",
            fontWeight: "500",
            marginBottom: "20px",
          }}
        ></Typography>
        <ContentLinearProgressBar percentage={2} />
      </Grid>
      <Grid
        item
        xs={0}
        sm={0}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop:{xs:"10px", sm:"10px", md:"0px"}
        }}
      >
        {user?.isUserPremium ? null : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img src={Lock} alt="" />
          </div>
        )}
        <SurveyStartAnswerButton
          _onClick={startSurveyTriggered}
          survey_token={survey_token}
        />
      </Grid>
    </Grid>
  );
};

export default SurveyForParentItem;
