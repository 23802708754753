import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { UserFeedBackContentType, UserService } from "../client";
import { setFeedback } from "../apiClient/services";

export const addEvaluationFeedBack = createAsyncThunk(
    'generalController/evaluateContent',
    async (payload:{
        rate:number | null, 
        text: string,
        type : "feedback" | "problem" | "suggestion" | "evaluate", // feedback, problem, suggestion, evaluate
        contentId: string | null,
        contentType:"article" | "traininget" | "survey" | "parentschoolmodule" | "parentschoolarticle" | null, // article, trainingset, survey, parentschoolmodule, parentschoolarticle or null
        appVersion : string | null,
        operatingSystem: "ios" | "android" | "web" | "other" | null,
        operatingSystemDetail: null | object
    }) => {
        try {
            return await setFeedback(payload.rate, payload.text, payload.type, payload.contentId, payload.contentType, payload.appVersion, payload.operatingSystem, payload.operatingSystemDetail)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IEvaluateContent {
    rate: number,
    text?: string
    content_id: string,
    content_type:"article" | "traininget" | "survey" | "parentschoolmodule" | "parentschoolarticle" | null
}
interface IInitialState {
    become_premium_modal_open: boolean
    share_content_modal_open: boolean
    rate_content_modal_open: boolean
    evaluation_data: IEvaluateContent
    evaluate_submit_loading:boolean
}

const initialState: IInitialState = {
    become_premium_modal_open: false,
    share_content_modal_open: false,
    rate_content_modal_open: false,
    evaluation_data: {
        rate: 0
    } as IEvaluateContent,
    evaluate_submit_loading:false
}

export const generalControllerSlice = createSlice({
    name: "generalController",
    initialState,
    reducers: {
        closeBecomePremiumModal(state) {
            state.become_premium_modal_open = false
        },
        openBecomePremiumModal(state) {
            state.become_premium_modal_open = true
        },
        closeShareContentModal(state) {
            state.share_content_modal_open = false
        },
        openShareContentModal(state) {
            state.share_content_modal_open = true
        },
        openContentEvaluateModal(state, action) {
            state.evaluation_data.content_id = action.payload.content_id
            state.evaluation_data.content_type = action.payload.content_type
            state.evaluation_data.rate = 0
            state.evaluation_data.text = undefined
            state.rate_content_modal_open = true
        },
        closeContentEvaluateModal(state) {
            state.rate_content_modal_open = false
        },
        setEvaluationData(state, action) {
            state.evaluation_data.rate = action.payload.rate
            state.evaluation_data.text = action.payload.text
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addEvaluationFeedBack.pending, (state) => {
            state.evaluate_submit_loading = true
        })
        builder.addCase(addEvaluationFeedBack.fulfilled, (state,action) => {
            state.rate_content_modal_open = false
            state.evaluate_submit_loading = false
        })
        builder.addCase(addEvaluationFeedBack.rejected, (state,action) => {
            state.rate_content_modal_open = false
            state.evaluate_submit_loading = false
        })
    }
})

export const { closeBecomePremiumModal, openBecomePremiumModal, closeShareContentModal, openShareContentModal,openContentEvaluateModal,closeContentEvaluateModal,setEvaluationData } = generalControllerSlice.actions
export default generalControllerSlice.reducer