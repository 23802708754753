import { FC } from "react";
import { Grid, Typography } from "@mui/material";
import ProfilePictureBlank from "./ProfilePictureBlank";
import CommonLabel from "./CommonLabel";
import CommonTextField from "./CommonTextField";
import CommonButton from "./CommonButton";
import { RedTrash } from "../assests";
import ChildrenBirthdateInput from "./ChildrenBirthdateInput";
import { RegisterChildBoy, RegisterChildGirl } from "../assests";
import { Gender } from "../client";

interface Props {
  child_id: string;
  name?: string;
  birthdate?: string;
  gender?:"male" | "female" | "other" | null ;
  submitUpdate: (child_id: string) => void;
  removeChildClicked: (id:string,name:string) => void;
}

const ChildPageListItem: FC<Props> = ({
  child_id,
  name = "",
  birthdate = "",
  gender,
  submitUpdate,
  removeChildClicked
}) => {
  const containerStyle = {
    background: "#FFFFFF",
    borderRadius: "8px",
    border: "1px solid #F0F0F0",
    diplay: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px 21px",
  };

  return (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} style={containerStyle}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "35px",
        }}
      >
        {
          gender == Gender.FEMALE ? (
            <img src={RegisterChildGirl} width={28} height={40} alt="" />
          ): gender == Gender.MALE ? 
            <img src={RegisterChildBoy} width={28} height={40} alt="" /> : (
              <span style={{height:"40px"}} ></span>
            )
        }
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
        <CommonLabel label="Çocuğunuzun İsmi" />
        <CommonTextField
          _defaultValue={name}
          _disabled={true}
        />
      </Grid>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{ textAlign: "start", marginBottom: "32px", marginTop: "24px" }}
      >
        <CommonLabel label="Çocuğunuzun Doğum Tarihi" />
        <ChildrenBirthdateInput
          _defaultValue={birthdate}
          _disabled={true}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonButton
          text="Bilgileri Güncelle"
          onClick={() => submitUpdate(child_id)}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: "16px",
          }}
        >
          <img src={RedTrash} alt="" />
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontSize: "12px",
              fontWeight: "400",
              lineHeight: "18px",
              color: "#FA4169",
              cursor: "pointer",
            }}
            onClick={() => removeChildClicked(child_id,name)}
          >
            Çocuklarımdan Çıkar
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default ChildPageListItem;
