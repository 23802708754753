import { FC } from 'react'
import { Typography } from '@mui/material'
import { CirclePlus } from '../assests'
import { IAreaOfInterest } from '../apiClient/models/areaOfInterest'

interface Props {
    interest: IAreaOfInterest
    _onClick:(e:string) => void
}

const AreaOfInterestItem:FC<Props> = ({
    interest,
    _onClick
}) => {
    const containerStyle = {
        background: "#FFFFFF",
        border: "1px solid #2574FF",
        borderRadius: "16px",
        padding:"13px 24px",
        display:"flex",
        marginLeft:"15px",
        marginBottom:"10px",
        cursor:"pointer"
    }

  return (
    <div style={containerStyle} onClick={() => _onClick(interest.id)}>
        <img src={CirclePlus} alt="" style={{marginRight:"3px", cursor:"pointer"}}/>
        <Typography style={{
            fontFamily:"Montserrat",
            fontSize:"13px",
            lineHeight:"18px",
            fontWeight:"400",
            color:"#000000",
            width:"110%"
        }}>
            {interest.name}
        </Typography>
    </div>
  )
}

export default AreaOfInterestItem