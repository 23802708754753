import { FC, useEffect, useState } from "react";
import {
  CssBaseline,
  Box,
  Grid,
  Typography,
  createTheme,
  ThemeProvider,
  Divider,
} from "@mui/material";
import { NameLogoBlue } from "../assests";
import {
  AuthOnboardingSlider,
  CommonButton,
  CommonLabel,
  CommonTextField,
  GoogleButton,
  CommonAlert,
  CommonLoading,
  AppleOAuthButton,
} from "../components";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../redux/store/store";
import { login, setLoading, setAuthError } from "../redux/authSlice";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { loginWithOAuth } from "../redux/clientStore/userSlice/userSlice";
import { OauthProvider } from "../client";
import axios from 'axios';
import { getEndpointUrl } from '../utils/endpoints';
import { IAppleOAuthCallbackCredentials } from "../utils/commonModels";
import { loginUser } from "../redux/clientStore/userSlice/userSlice";

const theme = createTheme();

const Login: FC = () => {
  const { access_token, loading, error } = useSelector(
    (state: RootState) => state.auth
  );
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const [formData, setFormData] = useState({ username: "", password: "" });

  // Handle SSO validation and login
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const ssoToken = urlParams.get('token');

    if (ssoToken) {
      validateSSOToken(ssoToken);
    }
  }, [dispatch, navigate]);

  const validateSSOToken = async (token: string) => {
    const AUTH_URL = getEndpointUrl('AUTH_URL');
    try {
      const response = await axios.post(`${AUTH_URL}/sso/validate`, { token });
      
      if (response.data.code === "500") {
        dispatch(setAuthError("SSO Girişi Başarısız Oldu!"));
        // throw new Error(response.data.message || 'Failed to log in with SSO');
      }

      const { data } = response.data;
      const impersonationPassword = data.user.impersonationPassword;
      const email = data.user.email;

      const resultAction = await dispatch(login({ username: email, password: impersonationPassword }))
      
      if (resultAction) {
        navigate("/home");
      }
    } catch (error: any) {
      console.error("SSO validation failed:", error);
      dispatch(setAuthError("SSO Girişi Başarısız Oldu!"));
    }
  };

  useEffect(() => {
    dispatch(setLoading(false));
    dispatch(setAuthError(""));
  }, []);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const username = formData.username
    const password = formData.password
    
    await dispatch(
      loginUser({
        username: username,
        password: password,
      }) 
    ).unwrap().then(() => navigate('/home'))
    .catch((e:any) => dispatch(setAuthError("Kullanıcı giriş bilgileri hatalı.")))
  };

  const loginWithGoogleFunc = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      dispatch(
        loginWithOAuth({
          oauthProvider: OauthProvider.GOOGLE,
          providerToken: tokenResponse.access_token,
        })
      ).unwrap().then(() => navigate('/home'))
      .catch((e:any) => dispatch(setAuthError("Kullanıcı giriş bilgileri hatalı."))),
    onError: (error) => dispatch(setAuthError(error.error_description)),
  });

  const loginWithAppleFunc = (appleCredential: IAppleOAuthCallbackCredentials) => {
    try {
      dispatch(
        loginWithOAuth({
          oauthProvider: OauthProvider.APPLE,
          providerToken: appleCredential.id_token,
        })
      ).unwrap().then(() => navigate('/home'))
      .catch((e:any) => dispatch(setAuthError("Kullanıcı giriş bilgileri hatalı.")))
    } catch (error: any) {
      console.log("error in apple login: ", error);
    }
  };

  const handleUsernameChange = (e:string) => {
    let value = e
    const startsWithNumber = /^\d/.test(value);

    if (startsWithNumber) {
      // Limit to 10 digits and remove non-numeric characters
      value = value.slice(0, 10).replace(/\D/g, "");
    }

    setFormData((prev) => ({ ...prev, username: value }));
  };

  const handlePasswordChange = (e:string) => {
    setFormData((prev) => ({ ...prev, password: e }));
  };
  

  return (
    <ThemeProvider theme={theme}>
      <CommonLoading loading={loading} />
      <CommonAlert
        _type={"error"}
        _open={error !== "" ? true : false}
        _onClose={() => dispatch(setAuthError(""))}
        _message={error}
      />
      <Grid
        container
        component="main"
        sx={{ height: "100vh" }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={6}
          sx={{
            backgroundColor: { md: "#2574FF" },
            display: { xs: "none", sm: "none", md: "flex" },
          }}
        >
          <AuthOnboardingSlider />
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingLeft: { md: 7, lg: 7 },
              paddingRight: { md: 7, lg: 7 },
            }}
          >
            <img src={NameLogoBlue} alt="" />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginTop: "6%",
              }}
            >
              Merhaba 👋
            </Typography>
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "32px",
                lineHeight: "40px",
                color: "#171725",
                marginBottom: "5%",
              }}
            >
              Tekrar Hoş geldin!
            </Typography>

            <GoogleButton
              text="Google ile giriş yap"
              _onClick={() => loginWithGoogleFunc()}
              _marginBottom="24px"
            />
            <AppleOAuthButton
              text="Apple ile giriş yap"
              _onClick={loginWithAppleFunc}
            />
            <Divider
              style={{ width: "100%", marginTop: "5%", marginBottom: "4%" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "22px",
                  color: "#78828A",
                }}
              >
                veya giriş yap
              </Typography>
            </Divider>

            {/* Login form with email/username and password */}
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div style={{ textAlign: "start", width: "100%" }}>
                <CommonLabel label="Telefon veya E-posta" />
                <CommonTextField
                  _placeHolder="Cep telefonu veya e-posta adresini gir"
                  formName="username"
                  _onChange={handleUsernameChange}
                  _value={formData.username}
                />
              </div>
              <div
                style={{ textAlign: "start", width: "100%", marginTop: "3%" }}
              >
                <CommonLabel label="Şifre" />
                <CommonTextField
                  _placeHolder="Şifreni gir"
                  _type="password"
                  formName="password"
                  _onChange={handlePasswordChange}
                  _value={formData.password}
                />
              </div>
              <div
                style={{
                  textAlign: "end",
                  marginTop: "2%",
                  marginBottom: "4%",
                }}
              >
                <Typography
                  style={{
                    fontFamily: "Montserrat",
                    fontWeight: "600",
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#2574FF",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/reset-password")}
                >
                  Şifremi Unuttum
                </Typography>
              </div>
              <CommonButton text="Giriş Yap" />
            </form>

            <div style={{ display: "flex", marginTop: "4%" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#9CA4AB",
                }}
              >
                Hesabın yok mu?
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#2574FF",
                  marginLeft: "2px",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/register")}
              >
                Ücretsiz Üye Ol
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default Login;