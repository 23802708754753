import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { getEndpointUrl } from '../utils/endpoints';
import { OpenAPI } from '../client';

const handleLogout = () => {
  localStorage.removeItem('accessToken');
  window.location.href = '/login';
};

const createApiClient = (baseURL: string): AxiosInstance => {
  const client = axios.create({
    baseURL,
  });

  client.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const internalConfig = config as InternalAxiosRequestConfig;
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        internalConfig.headers['Authorization'] = accessToken as string
      }
      return internalConfig;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error) => {
      // if (error.response && error.response.status === 401) {
      //   handleLogout();
      // }
      return Promise.reject(error);
    }
  );

  return client;
};

export const authClient = () => createApiClient(getEndpointUrl('AUTH_URL'));
export const userClient = () => createApiClient(getEndpointUrl('USER_URL'));
export const appointmentClient = () => createApiClient(getEndpointUrl('APPOINTMENT_URL'));
export const reportClient = () => createApiClient(getEndpointUrl('REPORT_URL'));
export const notificationClient = () => createApiClient(getEndpointUrl('NOTIFICATION_URL'));
export const surveyClient = () => createApiClient(getEndpointUrl('SURVEY_URL'));
export const paymentClient = () => createApiClient(getEndpointUrl('PAYMENT_URL'));
export const contentClient = () => createApiClient(getEndpointUrl('CONTENT_URL'));
export const chatBotClient = () => createApiClient(getEndpointUrl("CHAT_BOT_URL"));
export const streamClient = () => createApiClient(getEndpointUrl("STREAM_URL"));