import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { ChildCreateModel, ChildModel, UserService } from "../client";
import { ChildPatchModel } from "../client";
import { IChild, IChildCreateDto, IChildUpdateDto } from "../apiClient/models/child";
import { CreateChild, GetMyChildren, RemoveChildFromProfile, UpdateChild } from "../apiClient/services";

export const getAllChildren = createAsyncThunk(
    'child/getAllChildren',
    async () => {
        try {
            return await GetMyChildren()
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const updateSingleChild = createAsyncThunk(
    'child/updateSingleChild',
    async (payload:{childId:string, data:IChildUpdateDto}) => {
        try {
            const { childId, data } = payload
            return await UpdateChild(childId, { name:data.name, dateOfBirth:data.dateOfBirth, gender:data.gender })
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const removeChildFromUser = createAsyncThunk(
    'child/removeChildFromUser',
    async (payload: {child_id: string}) => {
        try {
            return await RemoveChildFromProfile(payload.child_id)
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addSingleChild = createAsyncThunk(
    'child/addSingleChild',
    async (child:IChildCreateDto) => {
        try {
            return await CreateChild(child)
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading: boolean
    error: string
    children: IChild[]
    child: IChild
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    children: [] as IChild[],
    child:{} as IChild
}

export const childSlice = createSlice({
    name: "child",
    initialState,
    reducers: {
        updateChildLocally: (state, action) => {
            state.child = action.payload
        },
        setLocalChild: (state, action) => {
            state.child = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllChildren.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(getAllChildren.fulfilled, (state, action) => {
            state.children = action.payload.data
            state.loading = false
            state.error = ""
        })
        builder.addCase(getAllChildren.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(updateSingleChild.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(updateSingleChild.fulfilled, (state, action) => {
            const index = state.children.findIndex((e) => e.id == action.meta.arg.childId)
            state.children[index].name = action.meta.arg.data.name
            state.children[index].dateOfBirth = action.meta.arg.data.dateOfBirth
            state.children[index].gender = action.meta.arg.data.gender
            state.loading = false
            state.error = ""
        })
        builder.addCase(updateSingleChild.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(removeChildFromUser.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(removeChildFromUser.fulfilled, (state, action) => {
            const childs = current(state.children)
            const filteredChildArr = childs.filter((e) => e.id !== action.meta.arg.child_id)
            state.children = filteredChildArr
            state.loading = false
            state.error = ""
        })
        builder.addCase(removeChildFromUser.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addSingleChild.pending, (state) => {
            state.loading = true
            state.error = ""
        })
        builder.addCase(addSingleChild.fulfilled, (state, action) => {
            state.children = [...state.children, action.payload.data]
            state.loading = false
            state.error = ""
        })
        builder.addCase(addSingleChild.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export default childSlice.reducer
export const { updateChildLocally,setLocalChild } = childSlice.actions