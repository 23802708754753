import { FC, useEffect } from 'react'
import { NameLogoBlue, CirculerPatern, MedalSuccess, AppleBlackLogo, AndroidBlackLogo } from '../assests'
import { Divider, Grid, Typography } from '@mui/material'
import { CommonLoading, CommonLightButton } from '../components'
import { OpenAPI } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { useNavigate } from "react-router";
import { getProfile } from '../redux/clientStore/userSlice/userSlice';

const PremiumCodeSuccess:FC = () => {
    const navigate = useNavigate()
    const { user } = useSelector(
      (state: RootState) => state.authMicro
    );
    const dispatch = useDispatch<AppDispatch>()
  
    useEffect(() => {
      if(user?.isUserPremium){
          navigate("/home")
      }else {
          dispatch(getProfile())
      }
    }, []);

  return (
    <>
    <img
      src={NameLogoBlue}
      alt=""
      style={{ marginTop: "20px", marginBottom: "20px", cursor:"pointer" }}
      onClick={() => navigate('/home')}
    />
    <Divider style={{ width: "100%", border: "1px solid #E9EBED" }} />
    <CommonLoading loading={false} />
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid
        item
        xs={4}
        sm={8}
        md={12}
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <img src={CirculerPatern} alt="" style={{ position: "relative" }} />
        <img
          src={MedalSuccess}
          alt=""
          style={{ position: "absolute" }}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "32px",
            color: "#171725",
            marginTop: "32px",
          }}
        >
          Premium Kod Kullanımı Başarılı!
        </Typography>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#9CA4AB",
            marginTop: "24px",
            marginBottom: "32px",
          }}
        >
          ParentWiser ailesine hoş geldiniz!
        </Typography>
        <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "20px",
              lineHeight: "24px",
              color: "#66707A",
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            Mobil cihazda <b style={{color:"#171725"}}>sorunsuz kullanabilmek</b> <br/> için uygulamayı tekrar açmanız <br/>
            gerekmektedir.
          </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <CommonLightButton
          text="Eğitime Başla"
          customCSS={{ width: "fit-content" }}
          fontSize="16px"
          onClick={() => navigate("/home")}
        />
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{marginBottom:"50px"}}>
        <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 500,
              fontSize: "16px",
              lineHeight: "24px",
              color: "#9CA4AB",
              marginTop: "32px",
              marginBottom: "16px",
            }}
          >
            Uygulamayı indirmek için tıklayınız:
          </Typography>
          <div>
            <img src={AppleBlackLogo} alt="" style={{marginRight:"5px", cursor:"pointer"}} onClick={() => window.open("https://apps.apple.com/tr/app/parentwiser-kids-development/id1527425284", "_blank")}/>
            <img src={AndroidBlackLogo} alt="" style={{marginLeft:"5px", cursor:"pointer"}} onClick={() => window.open("https://play.google.com/store/apps/details?id=com.parentwiser.parentingapp.android&gl=TR", "_blank")}/>
          </div>
        </Grid>
    </Grid>
  </>
  )
}

export default PremiumCodeSuccess