import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  CommonLoading,
  CommunicationPermissionSwitch,
  FontTypeAndSize,
  HeadingTitle,
  SettingsTabLabelItem,
} from "../components";
import { MenuCall, MenuFontType, MenuMailPlus, MenuRing } from "../assests";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { NotificationPermissions } from "../apiClient/models/notification";
import { GetNotificationPermissions, SetUserNotificationPermission } from "../apiClient/services";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

const Settings: FC = () => {
  const { user, loading } = useSelector((state: RootState) => state.authMicro);
  const dispatch = useDispatch<AppDispatch>();

  const tabNamesConstant = [
    {
      name: "Yazı Tipi ve Metin Boyutu",
      value: 0,
      icon: MenuFontType,
    },
  ];

  const [notificationPermission, setNotificationPermission] = useState<NotificationPermissions>({
    sms:false,
    mail:false,
    push: false
  })
  
  useEffect(() => {
    GetNotificationPermissions().then((e) => {
      setNotificationPermission(e.data)
    }).catch((e) => {})
  }, [])
  

  const permissionOnChange = async (e: any, permissionName:"sms" | "mail" | "push") => {
    const isChecked = e.target.checked

    await SetUserNotificationPermission({channel: permissionName, status: isChecked})
    setNotificationPermission(prev => ({ ...prev, [permissionName]:isChecked}))
  };

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading} />
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid
          item
          xs={4}
          sm={8}
          md={4}
          sx={{ textAlign: "start", borderRight: 1, borderColor: "divider" }}
        >
          <HeadingTitle title="Ayarlar" />
          {/* <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              "& .MuiTabs-indicator": { display: "none" },
              width: "100%",
            }}
          >
            {tabNamesConstant.map((item, index) => {
              return (
                <Tab
                  key={index}
                  disableRipple
                  label={
                    <SettingsTabLabelItem
                      isSelected={item.value == value}
                      text={item.name}
                      icon={item.icon}
                      isLastItem={false}
                    />
                  }
                  {...a11yProps(item.value)}
                />
              );
            })}
          </Tabs> */}
          <CommunicationPermissionSwitch
            text="Bildirim Al"
            icon={MenuRing}
            isChecked={
              notificationPermission.push
            }
            _onChange={(e) => permissionOnChange(e,"push")}
          />
          <CommunicationPermissionSwitch
            text="E-posta Al"
            icon={MenuMailPlus}
            isChecked={notificationPermission.mail}
            _onChange={(e) => permissionOnChange(e,"mail")}
          />
          <CommunicationPermissionSwitch
            text="SMS Al"
            icon={MenuCall}
            isChecked={notificationPermission.sms}
            _onChange={(e) => permissionOnChange(e,"sms")}
          />
        </Grid>
        <Grid item xs={4} sm={8} md={8}>
          {/* <TabPanel value={value} index={0}>
            <FontTypeAndSize />
          </TabPanel> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Settings;
