import { AxiosResponse } from "axios"
import { userClient } from "../apiClient"
import { IChild, IChildCreateDto, IChildrenCreateResponse, IChildUpdateDto } from "../models/child"

export const CreateChild = async(
    data: IChildCreateDto
): Promise<IChildrenCreateResponse<IChild>> => {
    try {
        const response: AxiosResponse<IChildrenCreateResponse<IChild>> = await userClient().post('/child', {
            name: data.name,
            dateOfBirth:data.dateOfBirth,
            gender: data.gender,
            schoolId: data.schoolId,
            classId: data.classId,
            parentIds: data.parentIds
        })
    
        return response.data
    } catch (error) {
        console.error('Error creating child:', error);
        throw new Error('Failed to create child');
    }
}

export const GetMyChildren = async(): Promise<IChildrenCreateResponse<IChild[]>> => {
    try {
        const response: AxiosResponse<IChildrenCreateResponse<IChild[]>> = await userClient().get('/child/me')
        return response.data
    } catch (error) {
        console.error('Error fetching get my child:', error);
        throw new Error('Failed fetching get my child');
    }
}

export const RemoveChildFromProfile = async(childId:string):Promise<any> => {
    try {
        const response: AxiosResponse<any> = await userClient().delete(`/child/${childId}`)
        return response.data
    } catch (error) {
        console.error('Error remove child:', error);
        throw new Error('Failed remove child');
    }
}

export const UpdateChild = async(
    childId:string,
    data: IChildUpdateDto
): Promise<IChildrenCreateResponse<IChild>> => {
    try {
        const response: AxiosResponse<any> = await userClient().put(`/child/${childId}`, {
            name: data.name,
            dateOfBirth:data.dateOfBirth.toString(),
            gender: data.gender,
        })
    
        return response.data
    } catch (error) {
        console.error('Error updating child:', error);
        throw new Error('Failed to update child');
    }
}