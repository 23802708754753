import { FC, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import PersonalInfoAreaInterestItem from "./PersonalInfoAreaInterestItem";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/store";
import AreaOfInterestItem from "./AreaOfInterestItem";
import { IAreaOfInterest, IUserInterests } from "../apiClient/models/areaOfInterest";
import { GetMyInterests } from "../apiClient/services";

interface Props {
  interests: Array<IAreaOfInterest>;
  userInterests: Array<IUserInterests>
  removeInterest:(e:string) => void
  addInterest:(e:string) => void
}

const AreaOfInterest: FC<Props> = ({ interests = [], userInterests = [], removeInterest, addInterest }) => {
  const { user } = useSelector((state: RootState) => state.authMicro);

  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      columnSpacing={2}
      rowSpacing={2}
    >
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "14px",
          }}
        >
          İlgilendiğim Konular
        </Typography>
      </Grid>
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "500",
            fontSize: "16px",
            lineHeight: "24px",
            color: "#78828A",
            marginBottom: "24px",
          }}
        >
          Çocuğunuzla ilgili size öncelikli yardımcı olmamızı istediğiniz
          konular:
        </Typography>
      </Grid>
      {userInterests.map((e) => {
            return <PersonalInfoAreaInterestItem interest={e.interest} _onClick={removeInterest}/>;
          })
      }
      <Grid item xs={4} sm={8} md={12}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: "600",
            fontSize: "20px",
            lineHeight: "28px",
            color: "#171725",
            marginBottom: "14px",
            marginTop: "40px",
          }}
        >
          Yeni İlgi Alanı Ekle
        </Typography>
      </Grid>
      {interests.filter((e) => !userInterests?.some((k) => e.id == k?.interest?.id)).map((e) => {
        return <AreaOfInterestItem interest={e} _onClick={addInterest}/>;
      })}
    </Grid>
  );
};

export default AreaOfInterest;
