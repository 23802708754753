import { FC, useState, useEffect } from "react";
import { Container, Grid, Tabs, Tab, Box } from "@mui/material";
import {
  CommonConfirmationAlert,
  CommonLoading,
  FamilyMemberCard,
  HeadingTitle,
  InviteFamilyMember,
  JoinExistingFamily,
  SettingsTabLabelItem,
} from "../components";
import { MenuSetProfileSelected, MenuUser, MenuUserAdd, MenuUserAddSelected } from "../assests";
import { Gender, ParentType, PartnerDetail } from "../client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import { removeFamilyMember } from "../redux/authSlice";
import { GetMyFamilyCode, GetMyPartners } from "../apiClient/services";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, textAlign: "start" }}>{children}</Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
};

interface IRemoveFamilyMemberSelected {
  id:string
  name:string
}

const ManageFamilyAccount: FC = () => {
  const [partnerCode, setPartnerCode] = useState<string>("");
  const [partners, setPartners] = useState<Array<PartnerDetail>>(
    [] as Array<PartnerDetail>
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [removeMemberSelected, setRemoveMemberSelected] = useState<IRemoveFamilyMemberSelected | null>(
    null
  );

  const { accessToken, user, loading:authLoading } = useSelector(
    (state: RootState) => state.authMicro
  );

  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setLoading(true);

    if (user?.partnerIds != undefined && user?.partnerIds?.length) {
      GetMyPartners()
        .then((e) => {
          const partners = e.data.map(k => ({
            id:k.id,
            name:k.name,
            gender: k.gender as Gender,
            parent_type: k.parentType as ParentType,
            is_profile_picture_uploaded: k.isProfilePictureUploaded
          }))
          setPartners(partners);
          setLoading(false);
        })
        .catch((k) => {
          setLoading(false);
        });
    } else {
      GetMyFamilyCode()
        .then((e) => {
          setPartnerCode(e.data.code);
          setLoading(false);
        })
        .catch((k) => {
          setLoading(false);
        });
    }
  }, []);

  const tabNamesConstant = [
    {
      name: "Aile Üyesi Davet Et",
      value: 0,
      icon: MenuUser,
      selectedIcon:MenuSetProfileSelected
    },
    {
      name: "Mevcut Aileye Katıl",
      value: 1,
      icon: MenuUserAdd,
      selectedIcon:MenuUserAddSelected
    },
  ];
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const removeConfirmation = () => {
    dispatch(removeFamilyMember({partner_id:removeMemberSelected?.id ? removeMemberSelected.id : ""}))
    setRemoveMemberSelected(null)
  }

  return (
    <Container
      maxWidth="xl"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={loading || authLoading} />
      <CommonConfirmationAlert
        isOpen={removeMemberSelected != null ? true : false}
        title="Hesabınıza kayıtlı aile üyesini aileden çıkarmak istediğinizden emin misiniz?"
        text={
          removeMemberSelected?.name + " adlı aile üyesi çıkarılacak onaylıyor musun?"
        }
        onCancelButtonFunc={() => setRemoveMemberSelected(null)}
        onSubmitButtonFunc={() => removeConfirmation()}
        cancelButtonText="İptal"
        submitButtonText="Çıkar"
      />
      {!loading && (
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              textAlign: "start",
              borderRight: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <HeadingTitle title="Aile Üyeliğini Yönet" />
          </Grid>
          {user?.partnerIds != undefined &&
          user.partnerIds.length > 0 ? (
            <>
              {partners.map((e, index) => {
                return (
                  <Grid key={index} item xs={4} sm={8} md={4}>
                    <FamilyMemberCard
                      id={e.id}
                      name={e.name}
                      is_profile_picture_uploaded={
                        e.is_profile_picture_uploaded
                      }
                      removeClicked = {() => setRemoveMemberSelected({id:e.id, name:e.name})}
                    />
                  </Grid>
                );
              })}
            </>
          ) : (
            <>
              <Grid
                item
                xs={4}
                sm={8}
                md={4}
                sx={{
                  textAlign: "start",
                  borderRight: 1,
                  borderColor: "divider",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },
                    width: "100%",
                  }}
                >
                  {tabNamesConstant.map((item, index) => {
                    return (
                      <Tab
                        key={index}
                        disableRipple
                        label={
                          <SettingsTabLabelItem
                            isSelected={item.value == value}
                            text={item.name}
                            icon={item.value == value ? item.selectedIcon : item.icon}
                            isLastItem={index == tabNamesConstant.length - 1}
                          />
                        }
                        {...a11yProps(item.value)}
                      />
                    );
                  })}
                </Tabs>
              </Grid>
              <Grid item xs={4} sm={8} md={8}>
                <TabPanel value={value} index={0}>
                  <InviteFamilyMember partnerCode={partnerCode} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <JoinExistingFamily />
                </TabPanel>
              </Grid>
            </>
          )}
        </Grid>
      )}
    </Container>
  );
};

export default ManageFamilyAccount;
