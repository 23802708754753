import { AxiosResponse } from "axios";
import { FamilyCode, IFamilyDto, Partner } from "../models/family";
import { userClient } from "../apiClient";

export const GetMyPartners = async(): Promise<IFamilyDto<Partner[]>> => {
    try {
        const response: AxiosResponse<IFamilyDto<Partner[]>> = await userClient().get('/family');
        return response.data;
    } catch (error) {
        console.error('Error fetching my partners:', error);
        throw new Error('Failed to fetch my partners');
    }
}

export const GetMyFamilyCode = async(): Promise<IFamilyDto<FamilyCode>> => {
    try {
        const response: AxiosResponse<IFamilyDto<FamilyCode>> = await userClient().get('/family/code');
        return response.data;
    } catch (error) {
        console.error('Error fetching family code:', error);
        throw new Error('Failed to fetch family code');
    }
}

export const JoinFamily = async(code:String): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await userClient().post('/family/', {
            partnerCode: code
        });
        return response;
    } catch (error) {
        console.error('Error join family:', error);
        throw new Error('Failed to join family');
    }
}