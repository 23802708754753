import { AxiosResponse } from "axios";
import { userClient } from "../apiClient";
import { IProfile } from "../models/user";

export const GetProfile = async():Promise<{data:IProfile}> => {
    try {
        const response: AxiosResponse<{data:IProfile}> = await userClient().get('/user/profile');
        return response.data;
    } catch (error) {
        console.error('Error fetching user profile:', error);
        throw new Error('Failed to fetching user profile');
    }
}

export const UpdateUser = async(userId:string, updated:any): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await userClient().put(`/user/${userId}`, updated)
        console.log(response.data)
        return response.data
    } catch (error) {
        console.error('Error updating user:', error);
        throw new Error('Failed to updating user');
    }
}

export const setFeedback = async(
    rate:number | null, 
    text: string,
    type : "feedback" | "problem" | "suggestion" | "evaluate", // feedback, problem, suggestion, evaluate
    contentId: string | null,
    contentType:"article" | "traininget" | "survey" | "parentschoolmodule" | "parentschoolarticle" | null, // article, trainingset, survey, parentschoolmodule, parentschoolarticle or null
    appVersion : string | null,
    operatingSystem: "ios" | "android" | "web" | "other" | null,
    operatingSystemDetail: null | object
): Promise<{success:boolean, message:string, code:string, data: any}> => {
    try {
        const response: AxiosResponse<{success:boolean, message:string, code:string, data: any}> = await userClient().post('/feedback', {
            rate,
            text,
            type,
            contentId,
            contentType,
            appVersion,
            operatingSystem,
            operatingSystemDetail
        })
        return response.data
    } catch (error) {
        console.error('Error set feedback:', error);
        throw new Error('Failed set feedback');
    }
}