import { AxiosResponse } from "axios";
import { IAreaOfInterest, IAreaOfInterests, IUserInterests } from "../models/areaOfInterest";
import { userClient } from "../apiClient";

export const GetAllAreaOfInterests = async (perPage: number = 30, page: number = 1, name: string = ""): Promise<IAreaOfInterests<IAreaOfInterest[]>> => {
    try {
        const response: AxiosResponse<IAreaOfInterests<IAreaOfInterest[]>> = await userClient().get('/areaofinterest', {
            params: {
                page,
                perPage,
                name,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching area of interests:', error);
        throw new Error('Failed to fetch area of interests');
    }
}

export const SetAreaOfInterests = async(interestIds: string[]):Promise<any> => {
    try {
        const response: AxiosResponse = await userClient().post('/areaofinterest/setUserInterest',{
            interestIds:interestIds
        });
        return response.data;
    } catch (error) {
        console.error('Error set area of interests:', error);
        throw new Error('Failed to set area of interests');
    }
}

export const GetMyInterests = async():Promise<IAreaOfInterests<IUserInterests[]>> => {
    try {
        const response: AxiosResponse<IAreaOfInterests<IUserInterests[]>> = await userClient().get('/areaofinterest/byUser');
        return response.data;
    } catch (error) {
        console.error('Error get user area of interests:', error);
        throw new Error('Failed to get user area of interests');
    }
}