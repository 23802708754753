import { Grid, Typography, Divider } from "@mui/material";
import { FC } from "react";
import { TickSolidGreen, TickSolidWhite } from "../assests";
import { PurchasePackageDescriptionMatters } from "../utils/constants";
import CommonButton from "./CommonButton";

interface Props {
  id: string;
  name: string;
  price: number;
  is_highlighted: boolean;
  onClick: (id:string) => void
}

const PurchasePackageCard: FC<Props> = ({
  id,
  name = "",
  price,
  is_highlighted = false,
  onClick
}) => {
  return (
    <Grid
      container
      columns={{ xs: 4, sm: 8, md: 12 }}
      style={{
        background: is_highlighted ? "#2574FF" : "#F9F9F9",
        border: "1px solid #E9EBED",
        borderRadius: "24px",
        padding: "40px",
      }}
    >
      <Grid item xs={4} sm={8} md={12} style={{ textAlign: "start" }}>
        <Typography
          style={{
            fontFamily: "Montserrat",
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "28px",
            color: is_highlighted ? "#F9F9F9" : "#171725",
          }}
        >
          {name}
        </Typography>
        <div
          style={{
            display: "flex",
            textAlign: "center",
            alignItems: "baseline",
          }}
        >
          {price.toString().split(".").length == 1 ? (
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 600,
                fontSize: "27px",
                lineHeight: "48px",
                color: is_highlighted ? "#E9EBED" : "#171725",
              }}
            >
              ₺{price}
            </Typography>
          ) : (
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "27px",
                  lineHeight: "48px",
                  color: is_highlighted ? "#E9EBED" : "#171725",
                }}
              >
                ₺{price.toString().split(".")[0]}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: is_highlighted ? "#E9EBED" : "#171725",
                }}
              >
                .{price.toString().split(".")[1]}
              </Typography>
            </div>
          )}
          <Typography
            style={{
              fontFamily: "Montserrat",
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "24px",
              color: is_highlighted ? "#F9F9F9" : "#9CA4AB",
            }}
          >
            /{id === 'life_time' ? 'Ömür Boyu' : 'Otomatik yenilenir'}
          </Typography>
        </div>
      </Grid>
      <Divider
        style={{
          width: "100%",
          border: "1px solid #E9EBED",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      />
      <Grid item xs={4} sm={8} md={12}>
        {PurchasePackageDescriptionMatters.map((e: string) => (
          <div
            style={{
              display: "flex",
              marginBottom: "19px",
              alignItems: "center",
              textAlign:"start"
            }}
          >
            <img
              src={is_highlighted ? TickSolidWhite : TickSolidGreen}
              alt=""
              style={{ marginRight: "12px" }}
            />
            <Typography
              style={{
                fontFamily: "Montserrat",
                fontWeight: 500,
                fontSize: "15px",
                lineHeight: "24px",
                color: is_highlighted ? "#F9F9F9" : "#171725",
              }}
            >
              {e}
            </Typography>
          </div>
        ))}
      </Grid>
      <Grid item xs={4} sm={8} md={12} style={{marginTop:"10px"}}>
        <CommonButton
          text="Devam Et"
          customCSS={{
            background: is_highlighted ? "#2574FF" : "#F9F9F9",
            border: is_highlighted ? "1px solid #E9EBED" : "1px solid #2574FF",
            borderRadius: "14px",
            padding:"12px 16px"
          }}
          textColor={is_highlighted ? "#F9F9F9" : "#2574FF"}
          onClick={() => onClick(id)}
        />
      </Grid>
    </Grid>
  );
};
export default PurchasePackageCard;