import { AxiosResponse } from "axios";
import { authClient } from "../apiClient";

export const SendOTP = async(phone:string = "", email:string =""): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await authClient().post('/sendOtp', {
            phone, email
        });
        return response;
    } catch (error) {
        console.error('Error sending otp', error);
        throw new Error('Failed to send otp');
    }
}

export const ForgotPassword = async(phone:string = "", email:string = "", otpCode:string, newPassword:string): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await authClient().post('/forgotPassword', {
            phone, email, otpCode, newPassword
        });
        return response;
    } catch (error) {
        console.error('Error forgot password', error);
        throw new Error('Failed to forgot password endpoint');
    }
}

export const UpdatePasswordInApp = async(newPassword:string): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await authClient().post('/changePassword', {
            newPassword
        });
        return response;
    } catch (error) {
        console.error('Error change password in app', error);
        throw new Error('Failed to change password in app');
    }
}