import { Container, Grid, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import {
  CommonLoading,
  HeadingTitle,
  HomeArticleSlider,
  HomeBulletin,
  HomeDailyQuote,
  HomeParentSchoolContunie,
  HomeParentSolutionSlider,
  HomePopularSearchItem,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  ArticleType,
  BulletinModel,
  GetDailyQuotesDto,
  OpenAPI,
  TrainingSetArticleItem,
  TrainingSetModel,
  UserService,
} from "../client";
import { getBulettins } from "../redux/bulettinSlice";
import {
  checkForcedUpdateParamExistInLocalStorage,
  getForcedUpdateUrl,
  removeForcedUpdateParamInLocalStorage,
} from "../utils/commonFuncs";
import { useNavigate } from "react-router";
import { getAllParentSolutions } from "../redux/parentSolutionSlice";
import { ITrainingSet, ITrainingSetList } from "../apiClient/models/trainingSet";
import { GetAllBulletins, GetRandomDailyQuote } from "../apiClient/services";
import { IBulletin } from "../apiClient/models/bulletin";
import { IDailyQuote } from "../apiClient/models/dailyQuote";

const Home: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { access_token } = useSelector((state: RootState) => state.auth);
  const { trainingSets, loading: ParentSolutionLoading } = useSelector(
    (state: RootState) => state.parentSolution
  );
  const { bulletin, loading: BulletinLoading } = useSelector(
    (state:RootState) => state.bulettin
  )

  const [dailyQuote, setDailyQuote] = useState<IDailyQuote>(
    {} as IDailyQuote
  );
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const forcedPremium = checkForcedUpdateParamExistInLocalStorage();
    if (forcedPremium) {
      const url = getForcedUpdateUrl();
      removeForcedUpdateParamInLocalStorage();
      navigate(url);
    }
  }, []);

  useEffect(() => {
    setLoading(true)
    try {
      if (OpenAPI.TOKEN == undefined && access_token != "") {
        OpenAPI.TOKEN = access_token;
      }
  
      GetRandomDailyQuote()
        .then((e) => {
          setDailyQuote(e.data);
        })
        .catch((k) => {});

      if(bulletin.length == 0) {
        dispatch(getBulettins({perPage:1,page:1, title:""}))
      }
      if (trainingSets.data.length == 0) {
        dispatch(getAllParentSolutions({ perPage:30, page:1, name:"" }));
      }
    } catch (error) {} finally {
      setLoading(false)
    }

  }, []);

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "30px" }}
    >
      <CommonLoading loading={ParentSolutionLoading && loading} />
      {!ParentSolutionLoading && !loading && (
        <Grid container columnSpacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={8}
            sx={{
              display: "flex",
              marginBottom: { xs: "20px", sm: "20px", md: "0px" },
            }}
          >
            <HomeDailyQuote
              id={Object.keys(dailyQuote).length > 2 ? dailyQuote.id.toString() : ""}
              quote={
                Object.keys(dailyQuote).length > 2
                  ? dailyQuote.quote
                    ? dailyQuote.quote
                    : ""
                  : ""
              }
              author={
                Object.keys(dailyQuote).length > 2
                  ? dailyQuote.author
                    ? dailyQuote.author
                    : ""
                  : ""
              }
              is_cover_image_uploaded={true}
            />
          </Grid>
          <Grid item xs={4} style={{ display: "flex" }}>
            <HomeParentSchoolContunie />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginTop: "48px" }}
          >
            <HeadingTitle
              title="Önerilen Ebeveyn Çözümleri"
              _marginBottom="24px"
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <HomeParentSolutionSlider
              items={trainingSets.data.map(
                (k: ITrainingSetList) => {
                  return {
                    id: k?.id,
                    title: k?.name,
                    video_article_count: k.videoArticleCount,
                    text_article_count: k.textArticleCount,
                    total_watch_time: k.videoDurationInSecond == 0 ? 0 : Math.floor(k.videoDurationInSecond / 60),
                    watchPercent: k.watchPercent
                  };
                }
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{ textAlign: "start", marginTop: "48px" }}
          >
            <HeadingTitle title="Popüler Aramalar" />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            style={{
              display: "flex",
              flexDirection: "row",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            <HomePopularSearchItem text="Korku" />
            <HomePopularSearchItem text="Uyku Sorunu" />
            <HomePopularSearchItem text="Teknoloji Bağımlılığı" />
          </Grid>
          <Grid
            item
            xs={4}
            sm={8}
            md={12}
            sx={{
              marginTop: "48px",
              display: "flex",
              flexDirection: {xs:"column",sm:"column", md:"row"},
              alignItems: "baseline",
              justifyContent: "space-between",
            }}
          >
            <HeadingTitle title="Bu Haftanın Bülteni" />
            <Typography
              sx={{
                fontFamily: "Montserrat",
                fontWeight: "700",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#2574FF",
                marginRight:{xs:0,sm:0,md:"20px"},
                textDecoration:"underline",
                cursor:"pointer"
              }}
              onClick={() => navigate("/bulletins")}
            >
              Tüm Bültenleri Gör
            </Typography>
          </Grid>
          <Grid item xs={4} sm={8} md={12} style={{ display: "flex" }}>
            {bulletin.length > 0 ? (
              <HomeBulletin
                id={bulletin[0].id.toString()}
                title={bulletin[0].title}
                description={bulletin[0].description}
                short_description={bulletin[0].shortDescription}
                is_picture_uploaded={
                  bulletin[0]?.isPictureUploaded
                    ? bulletin[0].isPictureUploaded
                    : false
                }
              />
            ) : null}
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Home;
