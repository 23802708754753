import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { UserService } from "../client";
import Error from "./Error";
import { CommonLoading } from "../components";
import html2canvas from "html2canvas";
import { streamClient } from "../apiClient/apiClient";


const ParentSchoolCertificate: FC = () => {
  const { encrypted_user_id } = useParams<{ encrypted_user_id: string }>();
  const [showError, setShowError] = useState<boolean>(false);

  const [url, setUrl] = useState<null | string>(null);

  const getCertificateDetails = async () => {
    streamClient().get(`/file/parentSchoolCertificate/${encrypted_user_id}`)
      .then((response) => {
        const { data, status } = response;
        console.log(data.data.url)
        if (data.data.url === "") {
          setShowError(true);
        }
        setUrl(data.data.url === "" ? null : data.data.url);

      })
      .catch((err: any) => {
        setShowError(true);
      });
  };

  useEffect(() => {
    getCertificateDetails();
  });

  const handleDownload = async (imageUrl: string) => {
    const element = document.getElementById("certificate"); // Replace 'element-to-screenshot' with the ID of the DOM element you want to capture
    if (!element) {
      console.error("Element not found");
      return;
    }

    const image = new Image();
    image.onload = () => {
      html2canvas(element)
        .then((canvas) => {
          const dataURL = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.href = dataURL;
          link.download = 'screenshot.png';
          link.click();
        })
        .catch((error) => {
          console.error('Error capturing screenshot:', error);
        });
    };
    image.src = imageUrl;
  };

  return (
    <>
      {showError ? (
        <Error></Error>
      ) : url === null ? (
        <CommonLoading loading={url === null ? true : false} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            id="certificate"
            src={url}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "auto",
              height: "auto",
            }}
            alt="Your Image"
          />
        </div>
      )}
    </>
  );
};

export default ParentSchoolCertificate;
