import { AxiosResponse } from "axios";
import { contentClient } from "../apiClient";
import { IWhatBringsYouOption, IWhatBringsYouOptions } from "../models/whatBringsYouOption";

export const GetAllWhatBringsYouOptions = async(): Promise<IWhatBringsYouOptions<IWhatBringsYouOption[]>>=> {
    try {
        const response: AxiosResponse<IWhatBringsYouOptions<IWhatBringsYouOption[]>> = await contentClient().get('/whatBringsUser');
        return response.data;
    } catch (error) {
        console.error('Error fetching get what brings you options:', error);
        throw new Error('Failed to fetch get what brings you options');
    }
}

export const SetWhatBringUser = async(id:string): Promise<any> => {
    try {
        const response: AxiosResponse<any> = await contentClient().post('/whatBringsUser/log',{
            id
        });
        return response.data;
    } catch (error) {
        console.error('Error submit what brings you options:', error);
        throw new Error('Failed submit what brings you options');
    }
}