import { FC, useEffect, useState } from "react";
import { Container, Grid, Typography } from "@mui/material";
import {
  AddChildBlankItem,
  ChildPageListItem,
  CommonButton,
  CommonConfirmationAlert,
  CommonLoading,
  HeadingTitle,
  RegisterChildGenderList,
  RegisterDatePicker,
  RegisterTextField,
  UpdateChildModal,
} from "../components";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../redux/store/store";
import {
  addSingleChild,
  getAllChildren,
  removeChildFromUser,
  setLocalChild,
} from "../redux/childSlice";
import { ChildCreateModel, ChildPatchModel, Gender, OpenAPI } from "../client";
import { updateSingleChild } from "../redux/childSlice";

interface IRemoveChildSelected {
  id: string;
  name: string;
}

const Children: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, error, children, child } = useSelector(
    (state: RootState) => state.child
  );
  const { user } = useSelector((state: RootState) => state.authMicro);

  const [removeChildSelected, setRemoveChildSelected] =
    useState<IRemoveChildSelected | null>(null);

  const [mode, setMode] = useState<string>("Listing");
  const [newChild, setNewChild] = useState<ChildCreateModel>(
    {} as ChildCreateModel
  );
  const [updateModalOpen, setUpdateModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllChildren());
  }, []);

  useEffect(() => {
    let date = new Date();
    const subDate = date.toISOString().split("T")[0];
    setNewChild({ ...newChild, date_of_birth: subDate.toString() as string });
  }, [mode]);

  const removeConfirmation = async() => {
    await dispatch(
      removeChildFromUser({
        child_id: removeChildSelected?.id ? removeChildSelected.id : "",
      })
    );
    setRemoveChildSelected(null);
  };

  const addNewChild = async () => {
    await dispatch(addSingleChild({name:newChild.name, dateOfBirth:newChild.date_of_birth, gender: newChild.gender, schoolId:null, classId:null, parentIds:[user?.id as string]}));

    await dispatch(getAllChildren());
    setMode("Listing");
  };

  const discardLocalNewChild = () => {
    setNewChild({} as ChildCreateModel);
    setMode("Listing");
  };

  const openChildUpdateModal = (child_id: string) => {
    const child = children.find((e) => e.id == child_id);
    dispatch(setLocalChild(child))
    setUpdateModalOpen(true);
  };

  const updateChildAndCloseModal = async() => {
    try {
      setUpdateModalOpen(false)

      const data = {
        name:child?.name ? child.name :'',
        dateOfBirth: child?.dateOfBirth ? child.dateOfBirth : new Date(),
        gender: child?.gender ? child.gender : "other"
      }
      
      await dispatch(updateSingleChild({
          childId:child.id,
          data
        }
      ))

      // window.location.reload()
    } catch (error) {}
  }

  return (
    <Container
      maxWidth="lg"
      style={{ marginTop: "30px", marginBottom: "40px" }}
    >
      <CommonLoading loading={loading} />
      <CommonConfirmationAlert
        isOpen={removeChildSelected != null ? true : false}
        title="Hesabınıza kayıtlı çocuğu silmek istediğinizden emin misiniz?"
        text={
          removeChildSelected?.name +
          " adlı çocuk kaydı silinecek onaylıyor musun?"
        }
        onCancelButtonFunc={() => setRemoveChildSelected(null)}
        onSubmitButtonFunc={() => removeConfirmation()}
        cancelButtonText="İptal"
        submitButtonText="Sil"
      />
      <UpdateChildModal
        isOpen={updateModalOpen}
        onClose={() => setUpdateModalOpen(false)}
        updateChild={updateChildAndCloseModal}
      />
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xs={4} sm={8} md={12} lg={12} style={{ textAlign: "start" }}>
          <HeadingTitle
            title={mode == "Listing" ? "Çocuklarım" : "Yeni Çocuk Ekle"}
            _marginBottom="32px"
          />
        </Grid>
        {mode == "Listing" ? (
          <>
            {children.map((e, index) => {
              return (
                <Grid item xs={4} sm={8} md={4} key={index}>
                  <ChildPageListItem
                    child_id={e.id}
                    name={e.name}
                    birthdate={e?.dateOfBirth?.toString().split("T")[0]}
                    gender={e.gender ?? "other"}
                    submitUpdate={openChildUpdateModal}
                    removeChildClicked={() =>
                      setRemoveChildSelected({
                        id: e.id,
                        name: e.name ? e.name : "",
                      })
                    }
                  />
                </Grid>
              );
            })}
            <Grid item xs={4} sm={8} md={4} style={{ display: "flex" }}>
              <AddChildBlankItem _onClick={() => setMode("Create")} />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={4} sm={8} md={5} style={{ textAlign: "start" }}>
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun adı
              </Typography>
              <RegisterTextField
                _placeHolder="Çocuğun adını gir"
                _placeHolderAlign="start"
                _onChange={(e: string) => setNewChild({ ...newChild, name: e })}
              />
            </Grid>
            <Grid md={7}></Grid>

            <Grid
              item
              xs={4}
              sm={8}
              md={5}
              style={{ textAlign: "start", marginTop: "22px" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun doğum tarihi
              </Typography>
              <RegisterDatePicker
                _placeHolder="Çocuğun doğum tarihini gir"
                _placeHolderAlign="start"
                _onChange={(e: string) =>
                  setNewChild({ ...newChild, date_of_birth: e })
                }
              />
            </Grid>
            <Grid md={7}></Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={5}
              style={{ textAlign: "start", marginTop: "22px" }}
            >
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "28px",
                  color: "#171725",
                  marginBottom: "18px",
                }}
              >
                Çocuğunuzun cinsiyeti
              </Typography>
              <RegisterChildGenderList
                selectedName={newChild?.gender}
                _onClicked={(e: Gender) =>
                  setNewChild({ ...newChild, gender: e })
                }
              />
            </Grid>
            <Grid md={7}></Grid>
            <Grid
              item
              xs={4}
              sm={8}
              md={5}
              style={{ textAlign: "center", marginTop: "30px" }}
            >
              <CommonButton
                text="Yeni Çocuk Ekle"
                disabled={
                  newChild?.name && newChild.date_of_birth && newChild.gender
                    ? false
                    : true
                }
                onClick={addNewChild}
              />
              <Typography
                style={{
                  fontFamily: "Montserrat",
                  fontSize: "18px",
                  fontWeight: "600",
                  lineHeight: "26px",
                  color: "#9CA4AB",
                  marginTop: "16px",
                  cursor: "pointer",
                }}
                onClick={discardLocalNewChild}
              >
                İptal
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default Children;
