import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetWhatBringsUserSuggestionItem, UserService } from "../client";
import { GetAllAreaOfInterests, GetAllWhatBringsYouOptions } from "../apiClient/services";
import { IAreaOfInterest } from "../apiClient/models/areaOfInterest";
import { IWhatBringsYouOption } from "../apiClient/models/whatBringsYouOption";

export const getAreaOfInterests = createAsyncThunk(
    'areaOfInterest/getAreaOfInterests',
    async() => {
        try {
            return await GetAllAreaOfInterests(50, 1, "")
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getWhatBringsYouOptions = createAsyncThunk(
    'areaOfInterest/getWhatBringsYouOptions',
    async () => {
        try {
            return await GetAllWhatBringsYouOptions()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface IInitialState {
    loading:boolean
    error:string
    interests:IAreaOfInterest[]
    whatBringsYouOptions:IWhatBringsYouOption[]
}

const initialState:IInitialState = {
    loading:false,
    error:"",
    interests: [] as Array<IAreaOfInterest>,
    whatBringsYouOptions: [] as Array<IWhatBringsYouOption>
}

export const areaOfInterestSlice = createSlice({
    name:"areaOfInterest",
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder.addCase(getAreaOfInterests.pending, (state) => {
            state.loading = true;
            state.error = ''
          })
          builder.addCase(getAreaOfInterests.fulfilled, (state, action) => {
            state.interests = action.payload.data
            state.loading = false;
            state.error = ''
          });
          builder.addCase(getAreaOfInterests.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
          })
          builder.addCase(getWhatBringsYouOptions.pending, (state) => {
            state.loading = true;
            state.error = ''
          })
          builder.addCase(getWhatBringsYouOptions.fulfilled, (state, action) => {
            state.whatBringsYouOptions = action.payload.data
            state.loading = false;
            state.error = ''
          });
          builder.addCase(getWhatBringsYouOptions.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
          })
    }
})

export default areaOfInterestSlice.reducer