import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreditCardTypes, IyzicoSaveExternalNewCard, PricingOptionModel, PurchaseService, SavedCard } from "../client";
import { paymentClient } from "../apiClient/apiClient";
import { AxiosResponse } from "axios";
import { GetSavedCardsResponse, MyCard } from "../apiClient/models/card";

export const getMySavedCards = createAsyncThunk(
    'purchase/getMySavedCards',
    async () => {
        try {
            const response:AxiosResponse<GetSavedCardsResponse<MyCard[]>> = await paymentClient().get('/payment/cards')
            return response.data.data
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const addNewCard = createAsyncThunk(
    'purchase/addNewCard',
    async (payload: IyzicoSaveExternalNewCard, { rejectWithValue }) => {
        try {
            const { cardHolderName, cardNumber, expireMonth, expireYear } = payload
            const response:AxiosResponse = await paymentClient().post('/payment/card', {
                expireYear,
                expireMonth,
                cardNumber,
                cardHoldername: cardHolderName
            })
            return response.status
        } catch (error: any) {
            return rejectWithValue(error?.message)
            // throw new Error(error?.message)
        }
    }
)

export const deletePaymentCard = createAsyncThunk(
    'purchase/deletePaymentCard',
    async (payload: { cardToken:string }) => {
        try {
            const response: AxiosResponse = await paymentClient().delete('/payment/card', {
                data: {
                    cardToken: payload.cardToken
                }
            })
            return response.status
        } catch (error: any) {
            throw new Error(error?.message)
        }
    }
)

export const getAvailablePurchasePackages = createAsyncThunk(
    'purchase/getAvailablePurchasePackages',
    async() => {
        try {
            return await PurchaseService.getCurrentPricingOptionsPurchaseGetCurrentPricingOptionsGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

export const getGiftDiscountRate = createAsyncThunk(
    'purchase/getGiftDiscountRate',
    async() => {
        try {
            return await PurchaseService.getDiscountPercentageInGiftPurchasePurchaseGetDiscountPercentageInGiftPurchaseGet()
        } catch (error:any) {
            throw new Error(error?.message)
        }
    }
)

interface SavedCardWithToken extends SavedCard {
    cardToken: string
}

interface IInitialState {
    loading: boolean
    error: string
    saved_cards: Array<SavedCardWithToken>
    save_card_error: string
    pricing_options: Array<PricingOptionModel>
    gift_discount_rate: number
}

const initialState: IInitialState = {
    loading: false,
    error: "",
    saved_cards: [] as Array<SavedCardWithToken>,
    save_card_error: "",
    pricing_options: [] as Array<PricingOptionModel>,
    gift_discount_rate: 1
}

export const purchaseSlice = createSlice({
    name: "purchase",
    initialState,
    reducers: {
        setSaveCardError(state) {
            state.save_card_error = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMySavedCards.pending, (state) => {
            state.loading = false
            state.error = ''
        })
        builder.addCase(getMySavedCards.fulfilled, (state, action) => {
            state.loading = false
            state.error = ''
            state.saved_cards = action.payload.map((e: MyCard) => ({
                cardToken: e.cardToken,
                card_bin_number: e.binNumber,
                card_last_four_digits: e.lastFourDigits,
                card_type: e.cardType as CreditCardTypes, 
                card_association: e.cardAssociation,
                card_family_name: e.cardFamily,
                bank_name: e.cardBankName
            }))
        })
        builder.addCase(getMySavedCards.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
        builder.addCase(addNewCard.pending, (state) => {
            state.loading = true
            state.save_card_error = ''
        })
        builder.addCase(addNewCard.fulfilled, (state, action) => {
            state.loading = false
        })
        builder.addCase(addNewCard.rejected, (state, action) => {
            state.loading = false
            state.save_card_error = 'Beklenmedik bir hata meydana geldi. Bizimle iletişime geçin.'
        })
        builder.addCase(deletePaymentCard.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(deletePaymentCard.fulfilled, (state, action) => {
            state.saved_cards = state.saved_cards.filter((e) => e.cardToken != action.meta.arg.cardToken)
            state.loading = false
        })
        builder.addCase(deletePaymentCard.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })


        builder.addCase(getAvailablePurchasePackages.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getAvailablePurchasePackages.fulfilled, (state, action) => {
            const packages = action.payload.filter(e => e.id != "weekly" && e.id != "life_time")
            state.pricing_options = packages.sort((a,b) => (a.order ? a.order : 0) - (b.order ? b.order : 0))
            state.loading = false
        })
        builder.addCase(getAvailablePurchasePackages.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })

        builder.addCase(getGiftDiscountRate.pending, (state) => {
            state.loading = true
            state.error = ''
        })
        builder.addCase(getGiftDiscountRate.fulfilled, (state, action) => {
            state.gift_discount_rate = action.payload.discount_percentage
            state.loading = false
        })
        builder.addCase(getGiftDiscountRate.rejected, (state, action) => {
            state.loading = false
            state.error = action.error.message || ''
        })
    }
})

export const { setSaveCardError } = purchaseSlice.actions
export default purchaseSlice.reducer